import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`28th May 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`New `}<a parentName="p" {...{
            "href": "type://CircuitBreakerRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRule.html"
          }}>{`type://CircuitBreakerRule`}</a>{` and `}<a parentName="p" {...{
            "href": "type://CircuitBreakerRuleWithContent:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRuleWithContent.html"
          }}>{`type://CircuitBreakerRuleWithContent`}</a>{` API let you define a complex
circuit breaking policy conveniently. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2726"
          }}>{`#2726`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`CircuitBreakerRule myRule =
    CircuitBreakerRule.of(
        // A failure if an Exception is raised.
        CircuitBreakerRule.onException(),
        // Neither a success nor a failure because
        // the request has not been handled by the server.
        CircuitBreakerRule.builder()
                          .onUnprocessed()
                          .thenIgnore(),
        // A failure if the response is 5xx.
        CircuitBreakerRule.onServerErrorStatus(),
        // A success if the response is 2xx.
        CircuitBreakerRule.builder()
                          .onStatusClass(HttpStatusClass.SUCCESS)
                          .thenSuccess(),
        // Neither a success nor a failure. Do not take this response into account.
        CircuitBreakerRule.builder().thenIgnore());
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now easily bind an MDC property to a `}<a parentName="p" {...{
            "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
          }}>{`type://RequestContext`}</a>{` and log it using a logging framework.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2634"
          }}>{`#2634`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2724"
          }}>{`#2724`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2729"
          }}>{`#2729`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`MDC.put("transactionId", "1234");
WebClient client = ...;
try (SafeCloseable ignored = Clients.withContextCustomizer(ctx -> {
    RequestScopedMdc.copy(ctx, "transactionId");
}) {
    assert MDC.get("transactionId").equals("1234");

    client.execute(...);
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now install a custom hook to `}<a parentName="p" {...{
            "href": "type://RequestContextStorage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContextStorage.html"
          }}>{`type://RequestContextStorage`}</a>{` in runtime, usually at startup time. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2723"
          }}>{`#2723`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`RequestContextStorage.hook(delegate -> new RequestContextStorageWrapper(delegate) {
    @Nullable
    @Override
    public <T extends RequestContext> T push(RequestContext toPush) {
        // Usually, you can just use RequestScopedMdc for this purpose.
        MDC.put("foo", toPush.attr(FOO));
        return super.push(toPush);
    }

    @Override
    public void pop(RequestContext current, @Nullable RequestContext toRestore) {
        MDC.remove("foo");
        super.pop(current, toRestore);
    }

    ...
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now set method parameter descriptions for annotated services in
`}<a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{` using Javadoc. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2697"
          }}>{`#2697`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now easily sanitize headers and content using `}<a parentName="p" {...{
            "href": "type://RegexBasedSanitizer:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RegexBasedSanitizer.html"
          }}>{`type://RegexBasedSanitizer`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1810"
          }}>{`#1810`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2721"
          }}>{`#2721`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`You can now easily call `}<inlineCode parentName="li">{`ownAttr()`}</inlineCode>{` from `}<a parentName="li" {...{
          "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
        }}>{`type://RequestContext`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2727"
        }}>{`#2727`}</a>
        <ul parentName="li">
          <li parentName="ul">{`You don't have to check if the context is `}<a parentName="li" {...{
              "href": "type://ClientRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html"
            }}>{`type://ClientRequestContext`}</a>{` or not anymore.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`root()`}</inlineCode>{` and `}<inlineCode parentName="li">{`ownAttrs()`}</inlineCode>{` are pulled up as well.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`You can now use Armeria 0.99.6 with Micrometer 1.3.9.
(It is recommended to use Micrometer 1.5.1, though.) `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2742"
        }}>{`#2742`}</a></li>
      <li parentName="ul">{`You can now retry even when the current `}<a parentName="li" {...{
          "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
        }}>{`type://EndpointGroup`}</a>{` is empty. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2736"
        }}>{`#2736`}</a></li>
      <li parentName="ul">{`You can send a request by setting the authority and scheme header to `}<a parentName="li" {...{
          "href": "type://RequestHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestHeaders.html"
        }}>{`type://RequestHeaders`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2730"
        }}>{`#2730`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2734"
        }}>{`#2734`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`&lt;zero-length response&gt;`}</inlineCode>{` in `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2732"
        }}>{`#2732`}</a></li>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` is not raised anymore due to the dependency order when Armeria is shaded.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2731"
        }}>{`#2731`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2735"
        }}>{`#2735`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`RetryStrategy`}</inlineCode>{` and `}<inlineCode parentName="li">{`RetryStrategyWithContent`}</inlineCode>{` are gone in favor of
`}<a parentName="li" {...{
          "href": "type://RetryRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRule.html"
        }}>{`type://RetryRule`}</a>{` and `}<a parentName="li" {...{
          "href": "type://RetryRuleWithContent:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRuleWithContent.html"
        }}>{`type://RetryRuleWithContent`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2726"
        }}>{`#2726`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`CircuitBreakerStrategy`}</inlineCode>{` and `}<inlineCode parentName="li">{`CircuitBreakerStrategyWithContent`}</inlineCode>{` are gone in favor of
`}<a parentName="li" {...{
          "href": "type://CircuitBreakerRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRule.html"
        }}>{`type://CircuitBreakerRule`}</a>{` and `}<a parentName="li" {...{
          "href": "type://CircuitBreakerRuleWithContent:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRuleWithContent.html"
        }}>{`type://CircuitBreakerRuleWithContent`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2726"
        }}>{`#2726`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`EndpointSelector.select()`}</inlineCode>{` (and thus EndpointGroup.select() as well) now returns `}<inlineCode parentName="li">{`null`}</inlineCode>{`
instead of throwing an `}<a parentName="li" {...{
          "href": "type://EmptyEndpointGroupException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EmptyEndpointGroupException.html"
        }}>{`type://EmptyEndpointGroupException`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2736"
        }}>{`#2736`}</a></li>
      <li parentName="ul">{`The method signature of `}<inlineCode parentName="li">{`ClientFactory.acquireEventLoop()`}</inlineCode>{` and `}<inlineCode parentName="li">{`EventLoopScheduler.acquire()`}</inlineCode>{`
have been changed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2736"
        }}>{`#2736`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.11.2 → 5.12.3`}</li>
      <li parentName="ul">{`grpc-kotlin-stub 0.1.1 → 0.1.2`}</li>
      <li parentName="ul">{`io.dropwizard.metrics 4.1.7 → 4.1.9`}</li>
      <li parentName="ul">{`Jetty 9.4.28.v20200408 → 9.4.29.v20200521`}</li>
      <li parentName="ul">{`reactor-grpc 1.0.0 → 1.0.1`}</li>
      <li parentName="ul">{`Retrofit 2.8.1 → 2.9.0`}</li>
      <li parentName="ul">{`Rxjava3 3.0.3 → 3.0.4`}</li>
      <li parentName="ul">{`Shaded dependencies`}
        <ul parentName="li">
          <li parentName="ul">{`Caffeine 2.8.2 → 2.8.4`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Example dependencies`}
        <ul parentName="li">
          <li parentName="ul">{`Kotlin 1.3.71 → 1.3.72`}</li>
          <li parentName="ul">{`Kotlin Coroutines 1.3.6 → 1.3.7`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['amitvc', 'andrey-tpt', 'anuraaga', 'codefromthecrypt', 'hueneburg', 'ikhoon', 'imasahiro', 'jrhee17', 'KarboniteKream', 'matsumana', 'minwoox', 'okue', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      